import React, { useState, useEffect } from 'react';

function Hero() {

  return (
    <div className="px-4 py-0 my-5 text-center">
        <h2 className="fw-bold">Discover ideas that make money 💻</h2>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Profit Hunt is a curated list of online projects that generate revenue</p>
        </div>
      </div>
  );
}

export default Hero;