import React, { useState, useEffect } from 'react';
import { collection, where, setDoc, query, orderBy, limit, startAt, getDocs, getCountFromServer, Timestamp, startAfter } from "firebase/firestore";
import { analytics, db } from "./fbConfig"
import { getPageCount } from './utils';
import { logEvent } from "firebase/analytics";
import ProjectRow from './Components/ProjectRow';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import "./Pagination.css"
import Hero from './Components/Hero';
import FilterView from './Components/FilterView';

/**
 * We'll load all projects under the current query.
 * (projects) => totalPageCount
 * (projects, currentPageIdx) => projectsInPage
 */
function App() {

  // Projects and pagination
  const projectCountPerPage = 25
  const [currentPageIdx, setCurrentPageIdx] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [projectSnaps, setProjectSnaps] = useState([])
  const [projectSnapsInPage, setProjectSnapsInPage] = useState([])
  const [categoryIdToName, setCategoryIdToName] = useState(new Map())

  // Filter and search
  const [currentCategoryId, setCurrentCategoryId] = useState(-1)
  const [currentSortBy, setCurrentSortBy] = useState("createdAt")

  const fetchCategoryIdToName = async () => {
    const categoriesQ = query(
      collection(db, "categories"),
      orderBy("id"),
    );
    const categoriesSnap = await getDocs(categoriesQ);
    let categoryIdToNameMap = new Map()
    categoriesSnap.forEach((categoryDoc) => {
      categoryIdToNameMap.set(categoryDoc.data().id, categoryDoc.data().name)
    });
    setCategoryIdToName(categoryIdToNameMap)
  }

  const fetchProjects = async (sortBy, filterToCategoryId) => {
    filterToCategoryId = parseInt(filterToCategoryId)
    const projectsRef = collection(db, "projects")
    // the default query, order by "createdAt"
    let q = query(projectsRef,
        orderBy(sortBy, "desc"),
        limit(4000))
    if (filterToCategoryId && filterToCategoryId > 0) {
      q = query(projectsRef,
          where("categories", "array-contains", filterToCategoryId),
          orderBy(sortBy, "desc"),
          limit(4000))
    }
    const querySnapshot = await getDocs(q)
    let projects = []
    querySnapshot.forEach(doc => {
      if (doc.data().pending !== true) {
        projects.push(doc)
      }
    });
    setProjectSnaps(projects)
    setTotalPageCount(getPageCount(projects.length, projectCountPerPage))
  }

  const didChangePage = pageIdx => {
    logEvent(analytics, "select_page", {
      value: pageIdx
    });
    setCurrentPageIdx(pageIdx)
  }

  // Initial fetch
  useEffect(() => {
    const initialFetch = async () => {
      // Fetch all categories
      await fetchCategoryIdToName()

      // Fetch all projects
      fetchProjects(currentSortBy, currentCategoryId)
    }
    initialFetch()
  }, [])

  useEffect(() => {
    const initialFetch = async () => {
      fetchProjects(currentSortBy, currentCategoryId)
    }
    initialFetch()
  }, [currentSortBy, currentCategoryId])

  // (projects, currentPageIdx) => projectsInPage
  useEffect(() => {
    const projectIdxStart = currentPageIdx * projectCountPerPage
    const projectIdxEnd = Math.min(projectIdxStart + projectCountPerPage, projectSnaps.length)
    setProjectSnapsInPage(projectSnaps.slice(projectIdxStart, projectIdxEnd))
  }, [projectSnaps, currentPageIdx])

  return (
    <div>
      <Hero />
      <div className='row'>
        <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
          <FilterView
            currentSortBy={currentSortBy}
            setCurrentSortBy={setCurrentSortBy}
            currentCategoryId={currentCategoryId}
            categoryIdToName={categoryIdToName}
            setCurrentCategoryId={setCurrentCategoryId}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2'>
          {projectSnapsInPage.map(projectSnap => (<ProjectRow
            key={projectSnap.data().rankId}
            name={projectSnap.data().name}
            link={projectSnap.data().link}
            description={projectSnap.data().description}
            sourceName={projectSnap.data().sourceName}
            sourceLink={projectSnap.data().sourceLink}
            mrr={projectSnap.data().mrr}
            oneOffRevenue={projectSnap.data().oneOffRevenue}
            revenueText={projectSnap.data().revenueText}
            businessModel={projectSnap.data().businessModel}
            createdAt={projectSnap.data().createdAt}
            categoryIds={projectSnap.data().categories}
            setCurrentCategoryId={setCurrentCategoryId}
            categoryIdToName={categoryIdToName}
          />))}
          <div className='mt-4'>
          <Pagination
            {...bootstrap5PaginationPreset}
            maxWidth={90}
            current={currentPageIdx+1}
            total={totalPageCount}
            onPageChange={page => didChangePage(page-1)}
          />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
