export const getPageCount = (projectCountTotal, projectCountPerPage) => {
  if (projectCountTotal <= 0) {
    return 1
  }
  if (projectCountPerPage <= 0) {
    // Invalid input. Return a random value to avoid crashes.
    return 1
  }
  let pageCount = Math.floor(projectCountTotal / projectCountPerPage)
  if (projectCountTotal % projectCountPerPage > 0) {
    pageCount += 1
  }
  return pageCount
}

/**
 * Example results: 5000 => 5k, 1200000 => 1.2m
 */
export const getReadableNumberString = (value) => {
  if (value >= 1000000) {
    // exactly a multiple of 1M
    if (value % 1000000 === 0) {
      return `${value/1000000}m`
    } else {
      return `${(value * 1.0/1000000).toFixed(1)}m`
    }
  } else if (value >= 1000) {
    // exactly a multiple of 1K
    if (value % 1000 === 0) {
      return `${value/1000}k`
    } else {
      return `${(value * 1.0/1000).toFixed(1)}k`
    }
  } else {
    return value
  }
}

const oneHourInMillis = 3600 * 1000
export const getReadableDateString = (time, now = new Date()) => {
  if (now - time < oneHourInMillis) {
    return "Just now"
  } else if (now - time < oneHourInMillis * 24) {
    // over 1 hour ago, but less than 24 hours ago
    const hoursElapsed = Math.floor((now - time)/oneHourInMillis)
    if (hoursElapsed === 1) {
      return "1 hour ago"
    } else {
      return `${hoursElapsed} hours ago`
    }
  } else if (now - time < oneHourInMillis * 48) {
    return "Yesterday"
  } else {
    return time.toLocaleDateString()
  }
}