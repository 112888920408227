import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

var config = {
  apiKey: "AIzaSyCNhrCwscMnNeLSPcHYEDzZRKQAfiS1EkY",
  authDomain: "profithunt-co.firebaseapp.com",
  projectId: "profithunt-co",
  storageBucket: "profithunt-co.appspot.com",
  messagingSenderId: "1013005337639",
  appId: "1:1013005337639:web:0a91aa1b4642ef7e577a02",
  measurementId: "G-WY3JWW565M"
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage, analytics }