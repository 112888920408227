import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import './index.css';
import App from './App';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <nav className="navbar navbar-expand-sm navbar-dark bg-dark" aria-label="Third navbar example">
    <div className="container-fluid">
      <a className="navbar-brand" href="/">Profit Hunt</a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarsExample03">
        <ul className="navbar-nav ms-auto mb-2 mb-sm-0">
          <li className="nav-item">
            <a className="nav-link" aria-current="page" href="https://mailchi.mp/ecd95572bc83/profit-hunt-newsletter" target="_blank">Newsletter</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://docs.google.com/forms/d/e/1FAIpQLSdcuIN_dg9Xes5f9kCXb-C_Th3DYJGKWafaBgXgZY7tcJxXMw/viewform?usp=sf_link" target="_blank">Submit a project</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <Container>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/2" element={<App />} />
      </Routes>
      <footer className="py-3 my-4">
    <ul className="nav justify-content-center">
      <li className="nav-item"><a className="nav-link px-2 text-muted" href="https://docs.google.com/forms/d/e/1FAIpQLSfN8XDXKvHcBEMoiiT6zmuq66j0BOfaDFPD8F9_MuC2t-qQew/viewform?usp=sf_link" target="_blank">Send us feedback</a></li>
      <li className="nav-item"><a className="nav-link px-2 text-muted" href="https://www.buymeacoffee.com/profithunt" target="_blank">Buy me a coffee ☕</a></li>
    </ul>
    <p className="text-center text-muted">&copy; Profit Hunt 2023</p>
  </footer>
    </Container>
  </BrowserRouter>
);