import React, { useState, useEffect } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from '../fbConfig';

/**
 * It should be a row.
 */
function FilterView({
  currentSortBy,
  setCurrentSortBy,
  currentCategoryId,
  setCurrentCategoryId,
  categoryIdToName,
}) {
  const getOptionElements = () => {
    const getElement = (categoryId, categoryName, isSelected) => {
      return (<option
        key={categoryId}
        value={categoryId}
        >
          {categoryName}
        </option>)
    }
    let elements = []
    elements.push(getElement(0, "All", currentCategoryId <= 0))
    for (let categoryId of categoryIdToName.keys()) {
      const categoryName = categoryIdToName.get(categoryId)
      const element = getElement(categoryId, categoryName, categoryId === currentCategoryId)
      elements.push(element)
    }
    return elements
  }

  const getSortByElements = () => {
    const getElement = (sortByField, textInUI, isSelected) => {
      return (<option
        key={sortByField}
        value={sortByField}
        >
          {textInUI}
        </option>)
    }
    return [
      getElement("createdAt", "Newest", currentSortBy === "createdAt"),
      getElement("mrr", "Monthly revenue", currentSortBy === "mrr"),
    ]
  }

  const categoryOptionDidChange = e => {
    const categoryId = parseInt(e.target.value)
    const categoryName = categoryIdToName.get(categoryId)
    logEvent(analytics, "select_category", {
      value: categoryName
    });
    setCurrentCategoryId(parseInt(e.target.value))
  }

  const sortByOptionDidChange = e => {
    logEvent(analytics, "select_sortby", {
      value: e.target.value
    });
    setCurrentSortBy(e.target.value)
  }

  return (
    <div className="row">
      <div className='col-9 offset-0 col-md-5 offset-md-2 mb-1'>
        <div className="input-group input-group-sm">
          <label className="input-group-text" htmlFor="categorySelect">Category</label>
          <select className="form-select" id="categorySelect" value={currentCategoryId} onChange={categoryOptionDidChange}>
            {getOptionElements()}
          </select>
        </div>
      </div>
      <div className='col-9 col-md-5  mb-1'>
        <div className="input-group input-group-sm">
          <label className="input-group-text" htmlFor="sortBySelect">Sort by</label>
          <select className="form-select" id="sortBySelect" value={currentSortBy} onChange={sortByOptionDidChange}>
            {getSortByElements()}
          </select>
        </div>
      </div>
    </div>
  );
}

export default FilterView;