import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { getReadableNumberString, getReadableDateString } from '../utils';

function ProjectRow({
  name,
  link,
  description,
  sourceName,
  sourceLink,
  businessModel,
  createdAt,
  categoryIds,
  categoryIdToName,
  setCurrentCategoryId,
  mrr, // monthly recurring revenue
  oneOffRevenue, // one-off revenue
  revenueText // a general comment on the revenue
}) {
  /**
   * The mrr field is always nonnull, so that it can be used in sorting.
   * Which means we'll still put a value there when the owner never provided the exact mrr.
   * Therefore, we don't treat the `mrr` field as the top priority in UI.
   */
  const getRevenueLabel = () => {
    // p0: the descriptive revenue text.
    if (revenueText && revenueText.length > 0) {
      return (<>
        <span className="fw-bold">{revenueText}</span>
      </>)
    }
    // p1: mrr
    else if (mrr && mrr > 0) {
      const revenueStr = `$${getReadableNumberString(mrr)}/month`
      return (<>
        <span className="fw-bold">{revenueStr}</span>
      </>)
    }
    else if (oneOffRevenue && oneOffRevenue >= 0) {
      const revenueStr = `$${getReadableNumberString(oneOffRevenue)}`
      return (<>
        <span className="fw-bold">{revenueStr} in total</span>
      </>)
    }
    // default
    else {
      return (<>
        <span className="fw-bold">Revenue not disclosed</span>
      </>)
    }
  }

  const getSourceLabel = () => {
    // If there is a link, then how the link
    if (sourceLink && sourceLink.length > 0) {
      return (<a href={sourceLink} target="_blank">
                {sourceName}
              </a>)
    } else {
      return (<>{sourceName}</>)
    }
  }

  const getCategoryButtons = () => {
    const getButton = categoryId => {
      const btnText = categoryIdToName.get(categoryId)
      const btnStyle = {
        paddingTop: "0",
        paddingBottom: "0",
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
      }
      const onClick = e => {
        console.log("onClick")
        setCurrentCategoryId(categoryId)
      }
      return (<button
          key={btnText}
          style={btnStyle}
          className='btn btn-secondary btn-sm me-2'
          onClick={onClick}
          >
          {btnText}
        </button>)
    }
    if (!categoryIds || categoryIds.length === 0) {
      return <></>
    }
    return (<>
      {categoryIds.map(categoryId => getButton(categoryId))}
    </>)
  }

  const getBusinessModelString = () => {
    if (businessModel && businessModel.length > 0) {
      return businessModel
    } else {
      return "Subscription"
    }
  }

  return (
    <div className="row border-bottom py-3">
      <div className='col'>
        <div className="row project_name">
          <div className='col-6'>
            <a href={link} target="_blank">{name}</a>
          </div>
          <div className='col-6'>
            <p className='text-secondary text-end'><small>{getReadableDateString(createdAt.toDate())}</small></p>
          </div>
        </div>
        <div className="row mt-0 mb-2">
          <div className='col'>{description}</div>
        </div>
        <div className="row pl-0 order-4">
          <div className='col-5 col-md-3'><span className="text-secondary">Revenue: </span></div>
          <div className='col'><span>{getRevenueLabel()}</span></div>
        </div>
        <div className="row pl-0 order-4">
          <div className='col-5 col-md-3'><span className="text-secondary">Business model: </span></div>
          <div className='col'><span>{getBusinessModelString()}</span></div>
        </div>
        <div className="row pl-0 order-5">
          <div className='col-5 col-md-3'><span className="text-secondary">Categories: </span></div>
          <div className='col'>
            {getCategoryButtons()}
          </div>
        </div>
        <div className="row pl-0 order-5">
          <div className='col-5 col-md-3'><span className="text-secondary">Source: </span></div>
          <div className='col'><span>{getSourceLabel()}</span></div>
        </div>
      </div>
    </div>
  );
}

export default ProjectRow;